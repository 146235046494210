<template>
  <router-link :to="to">
    <div class="notification">
      <div class="notification__title-wrapper">
        <component v-if="iconComponent" :is="iconComponent" />
        <BaseH3 :text="title" />
      </div>
      <BaseText
        :text="description"
        :isSmall="true"
        class="notification__description"
      />
    </div>
  </router-link>
</template>

<script>
import BaseH3 from "@/components/UI/Base/BaseH3.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";

export default {
  components: { BaseH3, BaseText },

  props: {
    title: String,
    description: String,
    iconComponent: {
      type: Object,
      required: false,
    },
    to: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped>
.notification {
  min-width: var(--layout-view-wrapper-narrow--width);

  padding: 20px;
  display: flex;
  flex-flow: column;
  row-gap: 7px;

  background-color: #fff;
  border-radius: 20px;
}
.notification:hover {
  padding: 19px;
  border: 1px solid #c3e2ff;
  box-shadow: 0px 0px 25px #c3e2ff;
  cursor: pointer;
}
.notification__title-wrapper {
  display: flex;
  column-gap: 10px;
}
.notification__description {
  opacity: 0.6;
}

@media (max-width: 1200px) {
  .notification {
    min-width: var(--layout-view-wrapper-narrow--width);

    padding: 19px;
    display: flex;
    flex-flow: column;
    row-gap: 6px;

    background-color: #fff;
    border-radius: 20px;

    border: 1px solid #c3e2ff;
    box-shadow: 0px 0px 25px #c3e2ff;
  }
  .notification__title-wrapper {
    display: flex;
    column-gap: 6px;
  }
}
</style>
